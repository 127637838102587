@font-face {
  font-family: Shapiro;
  src: url("./assets/font/Shapiro55MiddleExtd.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: Shapiro;
  src: url("./assets/font/Shapiro95SuperExtd.otf") format("opentype");
  font-weight: bold;
}

a {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #00ff0f;
  padding: 20px;
  background-color: #0202028b;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s linear;
}

a:hover {
  background-color: #00ff0f;
  color: #000;
}

a::before {
  position: absolute;
  content: url(./assets/img/rectange.svg);
  top: 0;
  right: 5px;
}

body {
  background-color: #020202;
}

.App {
  font-family: Shapiro;
  overflow: hidden;
  color: #fff;
  text-transform: uppercase;
}

span {
  word-wrap: break-word;
}

.bg-1 {
  position: absolute;
  z-index: 1;
  content: "";
  top: 50%;
  left: -10%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #00ff0d 0%,
    rgba(2, 2, 2, 0) 100%
  );
  transform: rotate(-13.95deg);
  width: 534.85px;
  height: 344px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Shapiro;
  font-weight: bold;
}
